.displayContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.selectedJournal {
    color: lightgrey;
}

.filterForm {
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: ;
}

#checkPara {
    margin:2px;
    padding:0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 320px;
    text-transform: capitalize;
    }


.linkButtonJournalList {
    margin: 50px 50 50 500px;
    background-color: rgb(154, 190, 87);
    margin-top: 15px;
    width: 100px;
    color:white;
    height: 2rem
}

.linkButtonNoJournalList {
    margin: 50px 50 50 500px;
    background-color: rgb(120, 180, 214);
    margin-top: 15px;
    width: 100px;
    color:white;
    height: 2rem
}

.divider {
    margin-top: 40px;
}

.introText {
    font-weight: 500;
    margin: 0
}

.manualJournalListHead {
    margin-bottom: 5px
}

.manualJournalListSubheading1 {
    color:grey;
    font-style: italic;
    font-weight: 400;
    margin:5px 5px 5px 0;
    font-size: 1rem;
    display: flex;
    justify-content: center;
}

.manualJournalListSubheading2 {
    color:grey;
    font-style: italic;
    font-weight: 400;
    margin:5px 5px 5px 0;
    font-size: 0.9rem;
    display: flex;
    justify-content: center;
}


.ifColorList {
    margin-bottom: 5px;
}

.noJournalError {
    text-align: center;
    color: rgb(228, 77, 51);
    margin: 10px 0 0 0;
}

.journalList{
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin: 20px 20px 10px 20px
}


.journalListRow {
    display:flex;
    flex-direction: row;
    align-items: center;
}

.journalListColumn {
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    width: auto
}

.journalListHeading {
    margin-top: 50px;
    flex-direction: column;
    display:flex;
    align-items: center;
}

.journalListSubHeading {
    margin-bottom: 10px;
    margin-top: 10px;
}

.addJournalSection {
    margin-right: 30px;
    margin-bottom: 20px;
}

.removeJournalSection {
    flex-direction: column;
    display:flex;
    margin-left: 30px;
}

@media (max-width: 850px) {
    .addJournalSection {
        margin-right: 0;
    }
    .removeJournalSection {
        margin-left: 0;
    }
    .journalList{
        display:flex;
        flex-direction: column;
    }
    .displayContainer{
        margin: 50px 20px 0 20px
    }
    .journalListHeading {
        margin-top: 0;
    }
}


.journalSelectSection {
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.removeJournalHeadings {
    display: flex;
    flex-direction: column;
    align-items: center;
}

hr {
    margin-top:40px;
    border: 1px solid lightgrey;
    width:90%;
}

.subheading {
    margin-top:20px;
    display: flex;
    justify-content: center;
}

.ifIndicator {
    margin: 0;
    padding: 0
}