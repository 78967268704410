.container {
    border-top: 1px solid grey;
    border-bottom: 1px solid grey;
    text-align: center;
    background-color: #d2d5ab; 
    background-image: url(./covers.png);
    color: white;
    display: flex;
    justify-content: center;
    width: 100%;
    height: auto;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 700;
}

.bannerTitle {
    font-size: 3rem;
}

.bannerSubtitle {
    font-size: 1.5rem;
}

.subBanner{
    margin: 10px 50px;
    text-align: justify;
    width: auto;
    font-weight: 400
}

.bannerText {
    background-color: rgba(0,0,0,0.5);
    width: 50%;
    height: 10%;
}

@media (max-width: 460px) {
    .bannerTitle {
        font-size: 2.8rem;
        margin-bottom: 0
    }
    .bannerText {
        width: 60%;
    }
    .bannerSubtitle {
        font-size: 1.3rem;
    }
}

@media (max-width: 400px) {
    .bannerTitle {
        font-size: 2.6rem;
    }
    .container {
        height: 5%;
    }
}