.reset {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
}

.resetButton {
margin-left: 20px;
margin-bottom: 0;
background-color: indianred;
width: 100px;
color:white;
height: 2rem;
margin-bottom: 0
}


@media (max-width: 400px) {
    .resetButton {
        margin-left: 20px;
    }
}